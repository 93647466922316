import React, { useContext, useEffect, useRef, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from './shared-functions.js';
import { Row, Col, Navbar, Nav, Image, Card, Button } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { CloudFog, Database, Pencil, ThumbsUp, ListMagnifyingGlass, Clock, Funnel, Lock, Sparkle, UsersFour, ArrowUp, ArticleNyTimes, BoundingBox, Export, Cube } from "@phosphor-icons/react";
import Logo from "./components/common/Logo.js";
import { PopupButton } from '@typeform/embed-react';
import { ThemeContext } from "./Theme.js";
import { BILLING_PLANS, CARDS } from './config.js';
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet";

export default function Home() {

  // const navigate = useNavigate();
  // const location = useLocation();
  const { theme } = useContext(ThemeContext);

  const benefitsRef = useRef(null);
  const platformRef = useRef(null);
  const pricingRef = useRef(null);

  const [item, setItem] = useState(CARDS[0]);

  let isPageWide = useMediaQuery('(min-width: 640px)');


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [0]);

  const scrollEffect = (targetRef) => {
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    CARDS.forEach((card, i) => setTimeout(
      () => setItem(card),
      (i + 1) * 1000, // 5000, 10000, 15000
    ));
  }, []);

  return (
    <Row style={{ justifyContent: 'center', width: '100%', height: '95vh', marginLeft: 0, marginRight: 0 }}>
      <Helmet>
        <script
          src="https://getlaunchlist.com/js/widget.js"
          type="text/javascript"
          defer
        />
      </Helmet>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
        <Navbar justify="true" fixed="top" variant={theme === 'light-theme' ? "light" : "dark"} style={{ padding: '10px 20px', backgroundColor: '#202124' }}>
          <Logo width={120} />
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link onClick={() => scrollEffect(benefitsRef)}>Benefits</Nav.Link>
              {isPageWide && <Nav.Link onClick={() => scrollEffect(platformRef)}>Platform</Nav.Link>}
              <Nav.Link onClick={() => scrollEffect(pricingRef)}>Pricing</Nav.Link>
            </Nav>
          </Navbar.Collapse> */}
        </Navbar>

        {/* , backgroundImage: `url('/assets/frontpage/waves.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' */}

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20, paddingBottom: 0, height: '70vh' }}>
          <Col xs={12} sm={12} md={11} lg={10} xl={10} style={{ padding: isPageWide ? 20 : 0, maxWidth: 1000, textAlign: 'center' }}>
            <h6>Your AI Research Assistant</h6>
            <h1 style={{ marginTop: isPageWide ? 30 : 20 }}>Elevate Your Research. <span className='muted'>Seamless Notes, Visuals, and Presentations.</span></h1>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: isPageWide ? 10 : 0 }}>
              <Col xs={11} sm={9} md={8} lg={8} xl={6} style={{ padding: 10 }}>
                {/* <p className='large muted'>Search, extract and synthesize data from diverse sources, including your own documents, to create notes, charts, and slides.</p> */}
                <div class="launchlist-widget" data-key-id="7dUfaA" data-height="160px" style={{ marginTop: 10 }}></div>
              </Col>
            </Row>
            {/* Works with all major LLMs */}
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: isPageWide ? 60 : 10 }}>
              <Col xs={8} sm={8} md={7} lg={6} xl={4} style={{ textAlign: 'center' }}>
                <p className='muted' style={{ textAlign: 'center' }}>Works with your choice of open or closed-source LLMs</p>
              </Col>
            </Row>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20, paddingBottom: 0 }}>
              <Image src={'/assets/icons/openai.png'} style={{ width: 24, objectFit: 'cover', marginRight: 12, marginLeft: 12 }} />
              <Image src={'/assets/icons/llama.png'} style={{ width: 30, objectFit: 'cover', marginRight: 12, marginLeft: 12 }} />
              <Image src={'/assets/icons/gemini.png'} style={{ width: 30, objectFit: 'cover', marginRight: 12, marginLeft: 12 }} />
              <Image src={'/assets/icons/anthropic.png'} style={{ width: 24, objectFit: 'cover', marginRight: 14, marginLeft: 14 }} />
              <Image src={'/assets/icons/mistral.png'} style={{ width: 22, objectFit: 'cover', marginRight: 16, marginLeft: 16 }} />
              <Image src={'/assets/icons/cohere.png'} style={{ width: 24, objectFit: 'cover', marginRight: 16, marginLeft: 16 }} />
            </Row>
          </Col>
        </Row>

        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0, paddingBottom: 0 }}>
          <Marquee speed={50} pauseOnHover gradient gradientColor={'var(--bg-primary)'} gradientWidth={100}>
            {CARDS.map((c, id) => {
              return (
                <Card key={id} className='card-pattern' style={{ display: 'flex', backgroundColor: 'transparent', height: 200, width: 160, boxShadow: '3px 3px 0px var(--separator)', marginRight: 20 }}>
                  <Row style={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, textAlign: 'center' }}>
                    <h4>{c.name}</h4>
                    {c.icon}
                  </Row>
                </Card>
              )
            })}
          </Marquee>
        </Row> */}

        <Footer />

        {/* <div style={{ position: 'fixed', bottom: 20, right: 20, height: 40, width: 40, padding: 10, cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}><ArrowUp size={20} color='var(--text-primary)' /></div> */}

      </Col>
    </Row>
  )


}
